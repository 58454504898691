<template>
  <div>
    <!-- 狀態 -->
    <v-btn class="pointer-events-none mr-4">
      <span :class="statusTextClass">
        {{'data.status'| t}} ({{statusLabel}})
      </span>
    </v-btn>

    <v-btn
      @click="save"
      v-hotkey="saveKeymap"
    >
      <span>{{'action.save'| t}} (Ctrl+S)</span>
      <v-badge :color="statusColor" dot>
        <v-icon>fa fa-save</v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  props: {
    popupConfig: Object,
  },
  mixins: [formActionMixins],
}
</script>

<style lang="sass" type="text/sass" scoped></style>