import { render, staticRenderFns } from "./treeNodeEditPopupActions.vue?vue&type=template&id=4885d88c&scoped=true&"
import script from "./treeNodeEditPopupActions.vue?vue&type=script&lang=babel&"
export * from "./treeNodeEditPopupActions.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4885d88c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})
